import { ResponsiveObject, Settings } from "react-slick";

import React from "react";
import SliderDots from "../components/General/Slider/SliderDots/SliderDots";

export const defaultSliderResponsiveSettings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  appendDots: (dots) => {
    return <SliderDots dots={dots} />;
  },
};
export const defaultSliderResponsiveObject: ResponsiveObject = {
  breakpoint: 768,
  settings: defaultSliderResponsiveSettings,
};

export const defaultSliderSettings: Settings = {
  dots: false,
  infinite: false,
  speed: 300,
  initialSlide: 0,
  nextArrow: <></>,
  prevArrow: <></>,
  responsive: [defaultSliderResponsiveObject],
};
