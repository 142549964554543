import * as styles from "./SliderDots.module.css";

import { ISVGColours } from "../../../../models/ComponentProps/SVGProps.model";
import React from "react";

const SliderDots = ({
  dots,
  colour = "blue",
  className = "",
  dotsClassName = "",
}: {
  dots: React.ReactNode;
  colour?: ISVGColours;
  className?: string;
  dotsClassName?: string;
}) => {
  const colourClass =
    colour === "black"
      ? styles.sliderDotsBlack
      : colour === "blue"
      ? styles.sliderDotsBlue
      : colour === "dark-blue"
      ? styles.sliderDotsDarkBlue
      : colour === "dark-green"
      ? styles.sliderDotsDarkGreen
      : colour === "green"
      ? styles.sliderDotsGreen
      : colour === "light-blue"
      ? styles.sliderDotsLightBlue
      : styles.sliderDotsWhite;
  return (
    <ul
      className={`${styles.sliderDots} ${colourClass} ${className} ${dotsClassName}`}
    >
      {dots}
    </ul>
  );
};

export default SliderDots;
